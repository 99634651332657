import React, {useEffect, useState, useCallback} from 'react';
import '../mail.scss';
import {Row, Col} from 'react-bootstrap';
import {getNameInitials, useQuery} from 'utils/common';
import {getSingleEmail} from 'containers/mail/api';
import {isEmpty, startCase} from 'lodash';

const EmailDetailView = () => {
  const query = useQuery();
  const id = query.get('id');
  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState(null);

  const fetchEmailDetail = useCallback(async () => {
    setLoading(true);
    const result = await getSingleEmail(id);
    if (result?.email) {
      setEmailData(result.email);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchEmailDetail();
  }, [fetchEmailDetail]);

  return (
    <div className="table-main facility-padding email-detail">
      <h5>{loading ? 'Loading...' : 'Email Details'}</h5>
      {!isEmpty(emailData) && !loading && (
        <Row>
          <Col md={4} className="mt-4">
            <label className="label mb-1">From Email</label>
            <p className="text">{emailData.from_email}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Reply-to Email</label>
            <p className="text">{emailData.reply_email}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">To Email</label>
            <p className="text">{emailData.to_email}</p>
          </Col>
          <Col md={12} className="mt-4">
            <label className="label mb-1">Subject</label>
            <p className="text">{emailData.subject || '-'}</p>
          </Col>
          <Col md={12} className="mt-4 content">
            <p className="text p-3">{emailData.template_body || '-'}</p>
          </Col>

          <Col md={4} className="mt-4">
            <label className="label mb-1">Email Status Code</label>
            <p className="table-text active">{startCase(emailData.status)}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Sent Date</label>
            <p className="text">{emailData.send_date}</p>
          </Col>
          <Col md={4} className="mt-4">
            <label className="label mb-1">Insert by</label>
            <div className="profile-otr d-flex" title={emailData.insert_by}>
              <div className="named-avatar">{getNameInitials(emailData.insert_by)}</div>
              <span className="line-height">{emailData.insert_by}</span>
            </div>
          </Col>

          <table className="table main-nested">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb bolder">
                        Clinician Name
                        {/* <ArrowsDownUp className="ms-2" size={18} /> */}
                      </p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">NPI Code</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Provider Category</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">From Date</p>
                    </div>
                  </th>
                  <th scope="col">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">To Date</p>
                    </div>
                  </th>


                </tr>
              </thead>
              <tbody>
                <>
                  <tr key={emailData.analysis.id} className="table-active">
                    <th scope="row">
                      <div className="table-text-otr ">
                        <span className="table-text bold">{emailData.analysis?.name || ''}</span>
                      </div>
                    </th>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text bold">{emailData.analysis?.npi_code || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text bold">{emailData.analysis?.provider_category_name}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text bold">{emailData.analysis?.start_date || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text bold">{emailData.analysis?.end_date || ''}</p>
                      </div>
                    </td>

                  </tr>
                  { emailData.analysis?.service_data?.length > 0 && (
                    <tr className="">
                      <td colSpan={6} className="nested-parent-td">
                        <div className="nested-table-wrapper app-scroller ">
                          <table
                            className={`table nested-table ${
                              Object.keys(emailData.analysis.service_data[0]).length > 6 ? 'scroll-table' : ''
                            }`}
                          >
                            <thead id="nested-table-header">
                              <tr>
                                {Object.keys(emailData.analysis.service_data[0])
                                  ?.reverse()
                                  .map((key, headIndex) => {
                                    return (
                                      <th scope="col">
                                        <div className="header-text-otr">
                                          <p className="table-name heading-xsb">
                                            {key.replace('_', ' ').toUpperCase()}
                                          </p>
                                        </div>
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {emailData.analysis?.service_data?.map(elem => {
                                return (
                                  <>
                                    <tr key={emailData.analysis.id}>
                                      {Object.keys(elem)
                                        ?.reverse()
                                        .map((elemKey, index) => {
                                          return index === 0 ? (
                                            <th
                                              scope="row"
                                              style={{paddingTop: '8px', paddingBottom: '8px'}}
                                              // style={{padding: '10px'}}
                                            >
                                              <div className="table-text-otr ">
                                                <span className="table-text">{elem[elemKey] || '-'}</span>
                                              </div>
                                            </th>
                                          ) : (
                                            <td
                                              style={{paddingTop: '8px', paddingBottom: '8px'}}
                                              // style={{padding: '10px'}}
                                            >
                                              <div className="table-text-otr">
                                                <p className="table-text">{elem[elemKey] || '-'}</p>
                                              </div>
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr className="nested-table-footer">
                                <th colSpan={2}>
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Total' || ''}</span>
                                  </div>
                                </th>
                                {Object.keys(emailData.analysis.service_data_total)
                                  ?.reverse()
                                  .map((elemKey, index) => {
                                    return (
                                      <td key={index}>
                                        <div className="table-text-otr">
                                          <p className="table-name heading-xsb">
                                            {emailData.analysis.service_data_total[elemKey] || ''}
                                          </p>
                                        </div>
                                      </td>
                                    );
                                  })}
                              </tr>
                              {!(
                                typeof emailData.analysis?.salary_contract === 'object' &&
                                Object.keys(emailData.analysis?.salary_contract)?.length
                              ) ? (
                                <tr className="nested-table-footer">
                                  <th colSpan={2}>
                                    <div className="table-text-otr ">
                                      <span className="table-name heading-xsb">{'Total Charges' || ''}</span>
                                    </div>
                                  </th>
                                  {Object.keys(emailData.analysis.service_data_total_amount)
                                    ?.reverse()
                                    .map((elemKey, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="table-text-otr">
                                            <p className="table-name heading-xsb">
                                              {emailData.analysis.service_data_total_amount[elemKey] || ''}
                                            </p>
                                          </div>
                                        </td>
                                      );
                                    })}
                                </tr>
                              ) : null}
                            </tfoot>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* second nested table  */}

                  { emailData.analysis?.service_data_by_facility_entity?.length > 0 && (
                    <tr className="">
                      <td colSpan={6}>
                        <table className="table nested-table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Entity</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Facility</p>
                                </div>
                              </th>
                              <th scope="col">
                                {/* <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Location</p>
                                </div>*/}
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Hours</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Units</p>
                                </div>
                              </th>
                              {!(
                                typeof emailData.analysis?.salary_contract === 'object' &&
                                Object.keys(emailData.analysis?.salary_contract)?.length
                              ) ? (
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Total Amount</p>
                                  </div>
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {emailData.analysis?.service_data_by_facility_entity?.map(elem => {
                              return (
                                <>
                                  <tr key={emailData.analysis.id}>
                                    <th
                                      scope="row"
                                      style={{paddingTop: '8px', paddingBottom: '8px'}}
                                      // style={{padding: '10px'}}
                                    >
                                      <div className="table-text-otr ">
                                        <span className="table-text">{elem?.entity_name || ''}</span>
                                      </div>
                                    </th>

                                    <td>
                                      <div className="table-text-otr" style={{width: 'auto'}}>
                                        <p className="">{elem?.facility_name || ''}</p>
                                      </div>
                                    </td>
                                    <td>
                                      {/*  <div className="table-text-otr">
                                        <p className="table-text">{elem?.location || ''}</p>
                                      </div>*/}
                                    </td>
                                    <td>
                                      <div className="table-text-otr">
                                        <p className="table-text">{elem?.total_hours || ''}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="table-text-otr">
                                        <p className="table-text">{elem?.total_serviced_unit || ''}</p>
                                      </div>
                                    </td>
                                    {!(
                                      typeof emailData.analysis?.salary_contract === 'object' &&
                                      Object.keys(emailData.analysis?.salary_contract)?.length
                                    ) ? (
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">
                                            {`$${Number(elem?.total_amount)?.toFixed(2)}` || ''}
                                          </p>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}

                  {/* third nested table  */}

                  { emailData.analysis?.service_data_adjustments?.length > 0 && (
                    <>
                      <tr className=" ">
                        <td colSpan={6}>
                          <p className="table-name heading-xsb adjustment-table-header ">Adjustment</p>
                        </td>
                      </tr>
                      <tr className=" " style={{borderRadius: '0px'}}>
                        <td colSpan={6}>
                          <table
                            className="table 
                          nested-table 
                          adjustment-table-body  
                          "
                          >
                            <thead>
                              <tr className="text-danger">
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Effective Date</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Service Date</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Facility</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Code</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Units</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Charge Amount</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {emailData.analysis?.service_data_adjustments?.map(elem => {
                                return (
                                  <>
                                    <tr key={emailData.analysis.id}>
                                      <th scope="row" style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem?.effective_date || ''}</span>
                                        </div>
                                      </th>

                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.service_date || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text" style={{maxWidth: '400px'}}>
                                            {elem?.facility_name || ''}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.earning_code || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">{elem?.service_units || ''}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">
                                            {elem?.charge_amount}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr className="nested-table-footer">
                                <th colSpan={6}>
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Total Adj.' || ''}</span>
                                  </div>
                                </th>
                                <td>
                                  <div className="table-text-otr">
                                    <p className="table-name heading-xsb">
                                      {'$ ' +
                                        emailData.analysis?.service_data_adjustments
                                          .reduce(
                                            (pre, current) =>
                                              Number(pre) + Number(current['charge_amount'].replace('$', '')),
                                            0,
                                          )
                                          .toFixed(2) || ''}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  )}

                  {
                    emailData.analysis?.salary_contract &&
                    emailData.analysis?.salary_contract?.length > 0 && (
                      <tr className="">
                        <td colSpan={8}>
                          <table className="table nested-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Salary contract</p>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb"></p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(emailData.analysis?.salary_contract).map(elem => {
                                return (
                                  <>
                                    <tr key={emailData.analysis.id}>
                                      <th
                                        scope="row"
                                        style={{paddingTop: '8px', paddingBottom: '8px'}}
                                        // style={{padding: '10px'}}
                                      >
                                        <div className="table-text-otr ">
                                          <span className="table-text">{elem || ''}</span>
                                        </div>
                                      </th>
                                      <td>
                                        <div>
                                          <p className="table-text">{emailData.analysis?.salary_contract[elem] || '-'}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}

                  {/* fivth table  */}

                  { emailData.analysis?.service_data_by_earning_code?.length > 0 && (
                    <tr className="">
                      <td colSpan={6}>
                        <table className="table nested-table mb-4">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Procedure Code</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Procedure Name</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Units</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">RVU Value</p>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="header-text-otr">
                                  <p className="table-name heading-xsb">Total RVUs</p>
                                </div>
                              </th>

                              {!(
                                typeof emailData.analysis?.salary_contract === 'object' &&
                                Object.keys(emailData.analysis?.salary_contract)?.length
                              ) ? (
                                <th scope="col">
                                  <div className="header-text-otr">
                                    <p className="table-name heading-xsb">Unit Amount</p>
                                  </div>
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {emailData.analysis?.service_data_by_earning_code?.map(elem => {
                              return (
                                <>
                                  <tr key={emailData.analysis.id}>
                                    <th
                                      scope="row"
                                      style={{paddingTop: '8px', paddingBottom: '8px'}}
                                      // style={{padding: '10px'}}
                                    >
                                      <div className="table-text-otr ">
                                        <span className="table-text">{elem?.earning_code || ''}</span>
                                      </div>
                                    </th>
                                    <td>
                                      <div>
                                        <p className="table-text">{elem?.earning_code_name || ''}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="table-text">{elem?.service_units}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="table-text">{elem?.unit_multiplier}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="table-text">{elem?.total_rvus}</p>
                                      </div>
                                    </td>

                                    {!(
                                      typeof emailData.analysis?.salary_contract === 'object' &&
                                      Object.keys(emailData.analysis?.salary_contract)?.length
                                    ) ? (
                                      <td>
                                        <div className="table-text-otr">
                                          <p className="table-text">
                                            {`$${Number(elem?.initial_unit_charge_amount)?.toFixed(2)}` || ''}
                                          </p>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                </>
                              );
                            })}
                            <tr className="nested-table-footer">
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                {' '}
                                <div className="table-text-otr ">
                                  <span className="table-name heading-xsb">{'RVU Total' || ''}</span>
                                </div>
                              </th>
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                <div className="table-text-otr margin-col">
                                  <p className="table-name heading-xsb">
                                    {emailData.analysis?.total_rvus || ''}
                                  </p>
                                </div>
                              </th>
                            </tr>
                            <tr className="nested-table-footer">
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                {' '}
                                <div className="table-text-otr ">
                                  <span className="table-name heading-xsb">{'Gross Total' || ''}</span>
                                </div>
                              </th>
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                <div className="table-text-otr margin-col">
                                  <p className="table-name heading-xsb">
                                    {(emailData.analysis?.gross_total || '')}
                                  </p>
                                </div>
                              </th>
                            </tr>
                            { emailData.analysis?.rollover_total && 
                              <tr className="nested-table-footer">
                                <th
                                  colSpan={
                                    typeof emailData.analysis?.salary_contract === 'object' &&
                                    Object.keys(emailData.analysis?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  {' '}
                                  <div className="table-text-otr ">
                                    <span className="table-name heading-xsb">{'Adjustment Total' || ''}</span>
                                  </div>
                                </th>
                                <th
                                  colSpan={
                                    typeof emailData.analysis?.salary_contract === 'object' &&
                                    Object.keys(emailData.analysis?.salary_contract)?.length
                                      ? 4
                                      : 5
                                  }
                                >
                                  <div className="table-text-otr margin-col">
                                    <p className="table-name heading-xsb">
                                      {(emailData.analysis?.rollover_total || '')}
                                    </p>
                                  </div>
                                </th>
                              </tr>
                            }
                            <tr className="nested-table-footer">
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                {' '}
                                <div className="table-text-otr ">
                                  <span className="table-name heading-xsb">{'Adjusted Total' || ''}</span>
                                </div>
                              </th>
                              <th
                                colSpan={
                                  typeof emailData.analysis?.salary_contract === 'object' &&
                                  Object.keys(emailData.analysis?.salary_contract)?.length
                                    ? 4
                                    : 5
                                }
                              >
                                <div className="table-text-otr margin-col">
                                  <p className="table-name heading-xsb">
                                    {emailData.analysis?.net_total || ''}
                                  </p>
                                </div>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
        </Row>
      )}
    </div>
  );
};

export default EmailDetailView;
